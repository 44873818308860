import $ from 'jquery';
import Inputmask from 'inputmask';

class Form {

  constructor() {

    this.body = document.body;
    this.buttonOpen = document.querySelectorAll(".js-form-button");
    this.buttonClose = document.getElementById("js-close-button");
    this.popup = document.getElementById("feedback-popup");
    this.blocker = document.getElementById("feedback-popup__blocker");
    this.form = document.getElementById("js-form");
    this.phone = document.querySelectorAll('.js-input-phone');
    this.success = document.querySelectorAll('.form__success');
    this.formBox = document.querySelectorAll('.js-form-box');
    this.agree = document.getElementById('agree');

    this.onClickOpenButton();
    this.onClickCloseButton();
    this.phoneMask();
    this.submitForm();
    this.clickOutForm();
  }

  clickOutForm() {
    this.blocker.addEventListener('click', () => {
      this.close();
    });
  }

  onClickOpenButton() {
    $(this.buttonOpen).each((index, button) =>  {
      button.addEventListener('click', () => {
        this.open();
      });
    });
  }

  onClickCloseButton() {
    this.buttonClose.addEventListener('click', () => {
      this.close();
    });
  }

  phoneMask() {
    $(this.phone).each((index, input) => {
      new Inputmask('+7 (999) 999-99-99').mask(input);
    });
  }

  open() {
    this.body.classList.add('scrolling-disable');
    this.popup.classList.add('feedback-popup__open');
  }

  close() {
    $('.form__success').addClass('hidden');
    $('.js-form-box').removeClass('hidden');
    this.body.classList.remove('scrolling-disable');
    this.popup.classList.remove('feedback-popup__open');
  }

  submitForm() {
    $(this.form).on('submit', (e) => {
      e.preventDefault();
      if (!this.agree) {
        return false;
      }

      let data = {
        name: $('input[name="name"]', this.form).val(),
        phone: $('input[name="phone"]', this.form).val(),
        contactsFullPhoneName: $('input[name="contactsFullPhoneName"]', this.form).val(),
        question: $('textarea[name="message"]', this.form).val(),
        _token: $('input[name="_token"]', this.form).val(),
      };
      $.ajax({
        url: "/feedback/send",
        type: "POST",
        data: data,
      })
      .done(function( data ) {
        if (data.message) {
          $('.form__success').removeClass('hidden');
          $('.js-form-box').addClass('hidden');
          $("#js-form")[0].reset();
        }
      });
    });
  }
}

export default new Form();
